<template>
	<div>
		<pui-datatable 
			:modelName="modelName"
			></pui-datatable>
	</div>
</template>

<script>

export default {
	name: 'mres-categoria-condicion-grid',
	data() {
		return {
			modelName: 'mrescategoriacondicion',
		}
	}
}
</script>
